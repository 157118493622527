import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Chocolate = makeShortcode("Chocolate");
const Box = makeShortcode("Box");
const Icon = makeShortcode("Icon");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <hr></hr>
    <h1>{`Icon Palette`}</h1>
    <h4>{`This page serves as a palette for all icons specific to this project.`}</h4>
    <p><strong parentName="p">{`NOTE: If you're looking for our base set of Icons please see the `}<a parentName="strong" {...{
          "href": "https://dai-ui.now.sh/icons"
        }}>{`Dai.ui Documentation`}</a>{`.`}</strong></p>
    <hr></hr>
    <Chocolate mdxType="Chocolate">
      <Box mdxType="Box">
        <Icon size={4} name="lightbulb" mdxType="Icon" />
        <h5>{`lightbulb`}</h5>
      </Box>
      <Box mdxType="Box">
        <Icon size={4} name="minus" mdxType="Icon" />
        <h5>{`minus`}</h5>
      </Box>
      <Box mdxType="Box">
        <Icon size={4} name="moon" mdxType="Icon" />
        <h5>{`moon`}</h5>
      </Box>
      <Box mdxType="Box">
        <Icon size={4} name="sun" mdxType="Icon" />
        <h5>{`sun`}</h5>
      </Box>
      <Box mdxType="Box">
        <Icon size={4} name="github" mdxType="Icon" />
        <h5>{`github`}</h5>
      </Box>
      <Box mdxType="Box">
        <Icon size={4} name="clipboard" mdxType="Icon" />
        <h5>{`clipboard`}</h5>
      </Box>
      <Box mdxType="Box">
        <Icon size={4} name="folder" mdxType="Icon" />
        <h5>{`folder`}</h5>
      </Box>
      <Box mdxType="Box">
        <Icon size={4} name="forum" mdxType="Icon" />
        <h5>{`forum`}</h5>
      </Box>
      <Box mdxType="Box">
        <Icon size={4} name="globe" mdxType="Icon" />
        <h5>{`globe`}</h5>
      </Box>
      <Box mdxType="Box">
        <Icon size={4} name="laptop" mdxType="Icon" />
        <h5>{`laptop`}</h5>
      </Box>
      <Box mdxType="Box">
        <Icon size={4} name="pencil" mdxType="Icon" />
        <h5>{`pencil`}</h5>
      </Box>
      <Box mdxType="Box">
        <Icon size={4} name="people" mdxType="Icon" />
        <h5>{`people`}</h5>
      </Box>
      <Box mdxType="Box">
        <Icon size={4} name="wrench" mdxType="Icon" />
        <h5>{`wrench`}</h5>
      </Box>
      <Box mdxType="Box">
        <Icon size={4} name="makerLogo" mdxType="Icon" />
        <h5>{`makerLogo`}</h5>
      </Box>
    </Chocolate>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      